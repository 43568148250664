import React from "react"
import { graphql } from "gatsby"
import "bulma/css/bulma.css"
import "../styles/urzadzenie.scss"
import _navigation from "../components/_navigation"
import Footer from "../components/footer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import ReactPlayer from "react-player"

export const query = graphql`
  query($slug: String!) {
    contentfulUrzadzenie(slug: { eq: $slug }) {
      nazwaUrzadzenia
      slug
      childContentfulUrzadzenieTrescRichTextNode {
        json
      }
    }
  }
`
const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const opis = node.data.target.fields.description && node.data.target.fields.description.pl
      const url = node.data.target.fields.file && node.data.target.fields.file.pl.url
      const width = node.data.target.fields.file && node.data.target.fields.file.pl.details.image.width
      const height = node.data.target.fields.file && node.data.target.fields.file.pl.details.image.height
      return node.data.target.fields ? (
        <React.Fragment>
          <br clear="left" />
          <img
            style={{ display: "block", margin: "0 auto", maxHeight: "450px" }}
            className="img-fluid"
            vspace="20"
            title={opis}
            height={height < 100 ? height * 1.3 : height}
            width={width < 300 ? width * 1.3 : width}
            alt={opis}
            src={url}
          />
        </React.Fragment>
      ) : (
        <div></div>
      )
    },
  },
  renderText: text => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderMark: {
    [MARKS.CODE]: embedded => (
      <ReactPlayer style={{ display: "block", margin: "0 auto", padding: "10px 0" }} url={embedded[1]} />
    ),
  },
}

const Urzadzenie = props => {
  const body = documentToReactComponents(
    props.data.contentfulUrzadzenie.childContentfulUrzadzenieTrescRichTextNode.json,
    options
  )
  const nazwaUrzadzenia = props.data.contentfulUrzadzenie.nazwaUrzadzenia
  return (
    <div>
      <_navigation />
      <section className="hero hero-small-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title kat-glowna-tytul">{nazwaUrzadzenia}</h1>
          </div>
        </div>
      </section>
      <section className="section container">
        <div className="is-medium container-opis-body">{body}</div>
      </section>
      <Footer />
    </div>
  )
}
export default Urzadzenie
